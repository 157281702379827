


import React from 'react';
import ClassAssembly  from 'views/ClassAssembly2E';

const ClassAssemblyPage = () => {
    return <ClassAssembly />;
  };
  
  export default ClassAssemblyPage;